@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Quicksand:wght@700&display=swap');

:root {
  --bg-primary: #0a0a0a;
  --text-primary: #ffffff;
  --accent-color: #ff9ecd;
  --nav-height: 60px;
  --accent-border: rgba(255, 158, 205, 0.3);
  --font-primary: 'Inter', sans-serif;
  --font-title: 'Quicksand', sans-serif;
}

/* Background Animation */
.App {
  min-height: 100vh;
  background: linear-gradient(45deg, #0a0a0a, #1a1a1a);
  position: relative;
  overflow: hidden;
  animation: gradientShift 15s ease infinite alternate;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App::before {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 158, 205, 0.1) 0%, transparent 50%);
  animation: rotate 20s linear infinite;
  top: -50%;
  left: -50%;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Navigation */
.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--nav-height);
  padding: 0 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;
  background: rgba(10, 10, 10, 0.95);
  backdrop-filter: blur(10px);
}

.nav-links {
  display: flex;
  gap: 40px;
  align-items: center;
  margin-right: 60px;
  position: relative;
}

.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.3s ease;
  opacity: 0.8;
}

.nav-link.chat-button {
  background: linear-gradient(45deg, #ff71c0, #ff9ecd);
  color: #000;
  font-weight: 600;
  padding: 10px 28px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(255, 113, 192, 0.3);
  border: none;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-left: 20px;
  white-space: nowrap;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.nav-link.chat-button::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(45deg, #ff71c0, #ff9ecd);
  opacity: 0;
  border-radius: inherit;
  animation: pulseButton 2s ease-in-out infinite;
}

@keyframes pulseButton {
  0%, 100% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(1.1);
  }
}

.nav-link.chat-button:hover {
  transform: translateY(-2px) scale(1.05);
  box-shadow: 0 8px 25px rgba(255, 113, 192, 0.4);
}

.nav-link.chat-button:active {
  transform: translateY(1px) scale(0.98);
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--accent-color);
  transition: width 0.3s ease;
}

.nav-link:hover {
  opacity: 1;
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.1);
}

.nav-link:hover .nav-icon,
.nav-link:hover .nav-logo {
  transform: scale(1.1);
  opacity: 1;
}

/* Chat Window */
.chat-window {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  background: rgba(18, 18, 18, 0.98);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

/* Desktop styles */
@media (min-width: 769px) {
  .chat-window {
    right: 40px;
    bottom: 40px;
    width: 400px;
    height: 600px;
    max-height: 80vh;
    border-radius: 24px;
    border: 1px solid rgba(255, 113, 192, 0.3);
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .chat-window {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
  }

  .chat-header {
    padding: max(env(safe-area-inset-top), 10px) max(env(safe-area-inset-right), 16px) 10px max(env(safe-area-inset-left), 16px);
  }

  .messages-container {
    flex: 1;
    padding: 10px max(env(safe-area-inset-right), 16px) 10px max(env(safe-area-inset-left), 16px);
  }

  .input-form {
    padding: 10px max(env(safe-area-inset-right), 16px) max(env(safe-area-inset-bottom), 10px) max(env(safe-area-inset-left), 16px);
  }

  .message {
    max-width: 90%;
  }

  .minimize-button,
  .close-button {
    width: 40px;
    height: 40px;
    font-size: 24px;
  }
}

/* Minimized state */
.chat-window.minimized {
  height: auto;
}

@media (max-width: 768px) {
  .chat-window.minimized {
    height: auto;
    bottom: 0;
    border-radius: 20px 20px 0 0;
  }
}

/* Landscape mode optimization */
@media (max-height: 500px) and (orientation: landscape) {
  .chat-window {
    height: 100%;
  }

  .messages-container {
    max-height: calc(100vh - 120px);
  }

  .chat-header {
    padding: 8px 16px;
  }

  .input-form {
    padding: 8px;
  }
}

/* Input form responsiveness */
.input-form {
  position: relative;
  display: flex;
  gap: 8px;
  background: rgba(255, 255, 255, 0.05);
}

.input-form input {
  font-size: 16px; /* Prevents zoom on iOS */
  padding: 12px;
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid var(--accent-border);
}

.chat-title {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;
}

.chat-controls {
  display: flex;
  gap: 8px;
}

.minimize-button,
.close-button {
  background: none;
  border: none;
  color: var(--text-primary);
  font-size: 24px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  padding: 4px 8px;
  border-radius: 4px;
}

.chat-window.minimized {
  height: auto !important;
  bottom: 0;
  border-bottom: none;
  border-radius: 24px 24px 0 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.chat-window.minimized .chat-header {
  padding: 8px 16px;
  border-bottom: none;
  background: rgba(18, 18, 18, 0.98);
  border-radius: 24px 24px 0 0;
}

.chat-window.minimized .chat-title {
  gap: 8px;
}

.chat-window.minimized .chat-title img {
  width: 24px;
  height: 24px;
}

.chat-window.minimized .chat-title h1 {
  font-size: 16px;
}

.chat-window.minimized .messages-container,
.chat-window.minimized .input-form {
  display: none;
}

/* Improved input form and send button */
.input-form {
  margin-top: auto;
  padding: 16px;
  background: rgba(40, 40, 40, 0.95);
  border-top: 1px solid rgba(255, 113, 192, 0.2);
  border-radius: 0 0 24px 24px;
  display: flex;
  gap: 12px;
}

.input-form input {
  flex: 1;
  padding: 12px 16px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 113, 192, 0.3);
  color: #fff;
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.input-form button {
  background: linear-gradient(45deg, #ff71c0, #ff9ecd);
  color: #000;
  font-weight: 600;
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 15px rgba(255, 113, 192, 0.2);
  position: relative;
  overflow: hidden;
}

.input-form button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 113, 192, 0.3);
}

.input-form button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background: rgba(255, 113, 192, 0.3);
}

.messages-container {
  height: calc(100% - 140px);
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 0;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 113, 192, 0.3) transparent;
}

.messages-container::-webkit-scrollbar {
  width: 6px;
}

.messages-container::-webkit-scrollbar-track {
  background: transparent;
}

.messages-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 113, 192, 0.3);
  border-radius: 20px;
}

/* Enhanced message bubbles */
.message {
  max-width: 85%;
  margin: 4px 0;
  opacity: 0;
  transform: translateY(20px);
  animation: messageIn 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.message.user {
  margin-left: auto;
  background: linear-gradient(135deg, rgba(255, 113, 192, 0.15), rgba(255, 158, 205, 0.1));
  border: 1px solid rgba(255, 113, 192, 0.3);
  padding: 14px 18px;
  border-radius: 18px 18px 4px 18px;
  box-shadow: 0 4px 15px rgba(255, 113, 192, 0.1);
}

.message.ai {
  margin-right: auto;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.03));
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 14px 18px;
  border-radius: 18px 18px 18px 4px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

@keyframes messageIn {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.95);
  }
  70% {
    transform: translateY(-5px) scale(1.02);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.message:hover {
  transform: translateX(5px) scale(1.02);
  box-shadow: 0 4px 20px rgba(255, 113, 192, 0.2);
}

.message-content {
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.3px;
  word-wrap: break-word;
}

.social-icons {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.social-icon {
  color: var(--text-primary);
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.social-icon:hover {
  opacity: 1;
}

h1 {
  color: var(--text-primary);
  padding: 0 20px 20px;
  margin: 0;
  font-size: 24px;
}

.typing-indicator {
  display: flex;
  gap: 4px;
  padding: 8px 0;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background: #ff71c0;
  border-radius: 50%;
  animation: typingBounce 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) { animation-delay: 0s; }
.typing-indicator span:nth-child(2) { animation-delay: 0.2s; }
.typing-indicator span:nth-child(3) { animation-delay: 0.4s; }

@keyframes typingBounce {
  0%, 60%, 100% {
    transform: translateY(0);
    opacity: 0.6;
  }
  30% {
    transform: translateY(-6px);
    opacity: 1;
  }
}

.nav-icon {
  font-size: 24px;
  color: var(--text-primary);
  transition: all 0.3s ease;
  opacity: 0.8;
}

.nav-logo {
  height: 24px;
  width: auto;
  object-fit: contain;
  transition: all 0.3s ease;
  opacity: 0.8;
}

.chat-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.waifu-icon {
  width: 28px;
  height: 28px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid var(--accent-color);
  padding: 2px;
}

.landing-content {
  padding: calc(var(--nav-height) + 40px) 60px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  gap: 60px;
  transform-origin: center;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  z-index: 1;
}

.chat-open .landing-content {
  transform: translateX(-200px) scale(0.95);
  opacity: 0.7;
  filter: blur(2px);
}

.landing-left {
  flex: 1;
  max-width: 600px;
}

.landing-title {
  font-family: var(--font-title);
  font-size: 72px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-shadow: 
    0 0 10px rgba(255, 113, 192, 0.8),
    0 0 20px rgba(255, 113, 192, 0.5),
    0 0 30px rgba(255, 113, 192, 0.3);
  animation: neonPulse 2s ease-in-out infinite;
}

.landing-title span {
  background: linear-gradient(45deg, #ff71c0, #ff9ecd, #ffa7d1, #ff71c0);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientFlow 8s ease infinite;
  position: relative;
}

.landing-title span::after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  filter: blur(8px);
  opacity: 0.8;
  background: inherit;
  -webkit-background-clip: text;
  background-clip: text;
}

@keyframes neonPulse {
  0%, 100% {
    text-shadow: 
      0 0 10px rgba(255, 113, 192, 0.8),
      0 0 20px rgba(255, 113, 192, 0.5),
      0 0 30px rgba(255, 113, 192, 0.3);
  }
  50% {
    text-shadow: 
      0 0 15px rgba(255, 113, 192, 0.9),
      0 0 25px rgba(255, 113, 192, 0.6),
      0 0 35px rgba(255, 113, 192, 0.4);
  }
}

@keyframes gradientFlow {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.heart-icon {
  font-size: 40px;
  color: #ff71c0;
  transition: all 0.3s ease;
}

.heart-icon.left {
  animation: floatLeft 3s ease-in-out infinite;
}

.heart-icon.right {
  animation: floatRight 3s ease-in-out infinite;
}

@keyframes floatLeft {
  0%, 100% {
    transform: translateX(0) translateY(0) rotate(-10deg);
    color: #ff71c0;
  }
  50% {
    transform: translateX(-10px) translateY(-5px) rotate(-5deg);
    color: #ff9ecd;
  }
}

@keyframes floatRight {
  0%, 100% {
    transform: translateX(0) translateY(0) rotate(10deg);
    color: #ff71c0;
  }
  50% {
    transform: translateX(10px) translateY(-5px) rotate(5deg);
    color: #ff9ecd;
  }
}

.landing-title:hover .heart-icon {
  transform: scale(1.2);
  filter: drop-shadow(0 0 10px rgba(255, 113, 192, 0.5));
}

.landing-description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 40px;
}

.feature-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--text-primary);
  font-size: 16px;
}

.feature-icon {
  font-size: 24px;
}

.landing-right {
  flex: 1;
  max-width: 600px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
}

.grid-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 16px;
  border: 1px solid rgba(255, 113, 192, 0.3);
  transition: all 0.3s ease;
}

.grid-image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 25px rgba(255, 113, 192, 0.2);
  border-color: rgba(255, 113, 192, 0.6);
}

.tech-note {
  margin-top: 24px;
  padding: 16px;
  border-radius: 12px;
  background: rgba(255, 113, 192, 0.1);
  border: 1px solid rgba(255, 113, 192, 0.2);
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  line-height: 1.6;
  backdrop-filter: blur(4px);
}

.beta-section {
  margin-top: 80px;
  padding: 40px;
  background: rgba(255, 113, 192, 0.05);
  border-radius: 24px;
  border: 1px solid rgba(255, 113, 192, 0.2);
  backdrop-filter: blur(10px);
}

.beta-content {
  max-width: 1200px;
  margin: 0 auto;
}

.beta-title {
  font-family: var(--font-title);
  font-size: 36px;
  color: var(--text-primary);
  text-align: center;
  margin-bottom: 40px;
  background: linear-gradient(45deg, #ff71c0, #ff9ecd);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.beta-features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 40px;
}

.beta-feature {
  display: flex;
  gap: 20px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  border: 1px solid rgba(255, 113, 192, 0.15);
  transition: all 0.3s ease;
}

.beta-feature:hover {
  transform: translateY(-5px);
  border-color: rgba(255, 113, 192, 0.3);
  box-shadow: 0 10px 30px rgba(255, 113, 192, 0.1);
}

.beta-icon {
  font-size: 32px;
  line-height: 1;
}

.beta-text h3 {
  color: var(--text-primary);
  font-size: 20px;
  margin: 0 0 12px 0;
  font-family: var(--font-title);
}

.beta-text p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  line-height: 1.6;
  margin: 0;
}

.beta-note {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
  background: rgba(255, 113, 192, 0.08);
  border-radius: 12px;
  border: 1px solid rgba(255, 113, 192, 0.2);
}

.beta-note p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
}

@media (max-width: 968px) {
  .beta-features {
    grid-template-columns: 1fr;
  }
  
  .beta-section {
    margin-top: 40px;
    padding: 20px;
  }
}

.footer {
  position: fixed;
  width: 100%;
  padding: 12px 20px;
  text-align: center;
  background: rgba(10, 10, 10, 0.8);
  border-top: 1px solid rgba(255, 113, 192, 0.15);
  bottom: 0;
  left: 0;
  z-index: 99;
  backdrop-filter: blur(8px);
  background: linear-gradient(
    to bottom,
    rgba(10, 10, 10, 0.6),
    rgba(10, 10, 10, 0.8)
  );
}

.contract-address {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 6px 16px;
  background: rgba(255, 113, 192, 0.06);
  border-radius: 20px;
  border: 1px solid rgba(255, 113, 192, 0.15);
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(255, 113, 192, 0.1);
}

.contract-address:hover {
  background: rgba(255, 113, 192, 0.1);
  transform: translateY(-1px);
  box-shadow: 0 4px 15px rgba(255, 113, 192, 0.15);
}

.contract-address span {
  color: var(--accent-color);
  font-weight: 500;
}

.contract-address a {
  color: var(--text-primary);
  text-decoration: none;
  font-family: monospace;
  font-size: 14px;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.contract-address a:hover {
  opacity: 1;
}

.chat-cta {
  position: fixed;
  right: 160px;
  top: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 99;
  padding: 8px 16px;
  animation: floatCTA 2s ease-in-out infinite;
}

@keyframes floatCTA {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.cta-text {
  color: var(--text-primary);
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
}

.arrow-path {
  width: 50px;
  height: 30px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23ff9ecd' stroke-width='2' viewBox='0 0 50 30'%3E%3Cpath d='M0 15h45M35 5l10 10-10 10'/%3E%3C/svg%3E") no-repeat center;
  opacity: 0.8;
  transform: rotate(-15deg);
}

@media (max-width: 768px) {
  .chat-cta {
    right: 120px;
    top: 10px;
  }
}

@media (max-width: 480px) {
  .chat-cta {
    right: 100px;
  }
  
  .arrow-path {
    width: 30px;
  }
}

/* Mobile-first responsive design */
@media (max-width: 768px) {
  .nav-container {
    padding: 0 20px;
  }

  .nav-links {
    gap: 20px;
    margin-right: 20px;
  }

  .chat-window {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    border-radius: 0;
    margin: 0;
  }

  .landing-content {
    flex-direction: column;
    padding: 20px;
  }

  .landing-left, .landing-right {
    width: 100%;
    max-width: 100%;
  }

  .image-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .beta-features {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .beta-feature {
    padding: 15px;
  }

  .footer {
    padding: 10px;
  }
}

/* Tablet optimization */
@media (min-width: 769px) and (max-width: 1024px) {
  .chat-window {
    width: 80%;
    right: 10%;
    height: 80vh;
  }

  .beta-features {
    grid-template-columns: repeat(2, 1fr);
  }

  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Landscape mode for mobile */
@media (max-height: 600px) and (orientation: landscape) {
  .chat-window {
    height: 100vh;
  }

  .messages-container {
    max-height: calc(100vh - 120px);
  }

  .input-form {
    padding: 10px;
  }
}

/* Large screens optimization */
@media (min-width: 1440px) {
  .chat-window {
    width: 450px;
    height: 700px;
    right: 40px;
    bottom: 40px;
  }

  .landing-content {
    max-width: 1400px;
    margin: 0 auto;
  }
}

/* Safe area insets for notched phones */
@supports (padding: max(0px)) {
  .chat-window {
    padding-bottom: max(20px, env(safe-area-inset-bottom));
    padding-left: max(20px, env(safe-area-inset-left));
    padding-right: max(20px, env(safe-area-inset-right));
  }
}

/* Mobile Navigation */
@media (max-width: 768px) {
  .nav-container {
    padding: 0 20px;
    height: 50px;
  }

  .nav-links {
    margin-right: 20px;
    gap: 20px;
  }

  .nav-link.chat-button {
    padding: 8px 20px;
    font-size: 13px;
  }
}

/* Mobile Landing Content */
@media (max-width: 768px) {
  .landing-content {
    padding: 60px 20px;
    text-align: center;
  }

  .tech-note {
    font-size: 14px;
    padding: 15px;
    margin: 20px 0;
  }

  .image-grid {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 10px;
  }

  .grid-image {
    border-radius: 12px;
  }
}

/* Improved Mobile Chat Window */
@media (max-width: 768px) {
  .chat-window {
    position: fixed;
    width: 100%;
    height: calc(100% - 50px); /* Subtract nav height */
    top: 50px; /* Start below nav */
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    margin: 0;
  }

  .chat-header {
    padding: 10px 15px;
    height: 50px;
  }

  .chat-title h1 {
    font-size: 18px;
  }

  .waifu-icon {
    width: 30px;
    height: 30px;
  }

  .messages-container {
    height: calc(100% - 110px); /* Subtract header and input heights */
    padding: 10px;
  }

  .message {
    max-width: 85%;
    margin: 8px 0;
    font-size: 14px;
  }

  .input-form {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background: rgba(18, 18, 18, 0.95);
    border-top: 1px solid rgba(255, 113, 192, 0.2);
  }

  .input-form input {
    height: 40px;
    font-size: 16px;
  }

  /* Improved minimized state */
  .chat-window.minimized {
    height: auto;
    top: auto;
    bottom: 0;
    border-radius: 20px 20px 0 0;
    background: rgba(18, 18, 18, 0.98);
  }
}

/* Small mobile devices */
@media (max-width: 360px) {
  .nav-link.chat-button {
    padding: 6px 16px;
    font-size: 12px;
  }

  .message {
    max-width: 90%;
    font-size: 13px;
  }
}

.chat-hint {
  position: fixed;
  right: 40px;
  bottom: 100px;
  background: rgba(255, 113, 192, 0.95);
  padding: 12px 20px;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  z-index: 98;
  opacity: 0;
  transform: translateY(20px);
  animation: showHint 1s forwards;
  animation-delay: 2s;
  pointer-events: none;
}

@keyframes showHint {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chat-pointer {
  position: fixed;
  right: 140px;
  top: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1000;
}

.pointer-heart {
  color: #ff71c0;
  font-size: 24px;
  text-shadow: 
    0 0 10px rgba(255, 113, 192, 0.8),
    0 0 20px rgba(255, 113, 192, 0.4);
  animation: pulseHeart 1.5s ease-in-out infinite;
}

.pointer-arrow {
  width: 40px;
  height: 24px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23ff71c0' stroke-width='3' viewBox='0 0 40 24'%3E%3Cpath d='M0 12h35M25 2l10 10-10 10'/%3E%3C/svg%3E") no-repeat center;
  filter: drop-shadow(0 0 8px rgba(255, 113, 192, 0.6));
  transform: rotate(-15deg);
}

@keyframes pulseHeart {
  0%, 100% {
    transform: scale(1);
    opacity: 0.9;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
}
